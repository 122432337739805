import logo from './logo.svg';
import './App.css';
import Display from './Component/Display';

function App() {
  return (
    <Display>
    </Display>
  );
}

export default App;
