import React from 'react';
import styled from 'styled-components';

const displayWrapper = styled.div`
    margin: auto;
`;

const Display = () => {
    return (
        <displayWrapper>
            MainPage입니다
        </displayWrapper>
    );
};

export default Display;